import {phaseTypes} from '@swiss-ski/sski-entity-types';

export const SUBTOPICS_ID = ['introduction-home', 'introduction-what', 'introduction-howmuch', 'introduction-environment'];

export const CATEGORY_ID = 'id';

export const FIXED_CATEGORY = 'sponsorship';

export const phaseNamesMapper = {
    E: phaseTypes.ELITE,
    F: phaseTypes.FOUNDATION,
    M: phaseTypes.MASTERY,
    T: phaseTypes.TALENT,
};

export const EMPTY_ELEMENT = 'empty';
