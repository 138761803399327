import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as dashboardActionTypes from '../dashboardActionTypes';

const loadTopics = function* loadTopics() {
    const {serviceManager} = yield select(state => state.application);

    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_TOPICS_REQUEST,
        ftemStrapiService.getTopics,
    );

    const responseAction = yield take([
        dashboardActionTypes.FETCH_TOPICS_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_TOPICS_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {topicDTOs} = response;
        yield put({
            type: dashboardActionTypes.STORE_TOPICS,
            payload: {
                topicDTOs,
            },
        });
    }
};

export default loadTopics;
