import React, {useState} from 'react';
import './PrintData.scss';
import PrintModal from '../../application/modals/PrintModal';

const PrintData = ({selectedColumn, isPrintUrl, printTitle}) => {
    const [isOpen, setIsOpen] = useState(isPrintUrl ?? false);

    const handlePrint = () => {
        try {
            if (!document.execCommand('print', false, null)) {
                window.print();
            }
        } catch {
            window.print();
        }
    };

    const handleOpenModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`sski-c-top-navigation-bar__print_container ${isOpen ? 'is-absolute' : ''}`}>
            <button onClick={handleOpenModal} className="sski-c-top-navigation-bar__print_button  button is-white is-rounded is-responsive is-size-6 has-text-weight-medium	">
                {selectedColumn} Stufendossier
            </button>
            {isOpen && (
            <PrintModal
                closeModal={handleOpenModal}
                printColumn={handlePrint}
                selectedColumn={selectedColumn}
                printTitle={printTitle}
            />
            )}
        </div>
    );
};

export default PrintData;
