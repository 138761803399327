import React, {useContext, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import './DataCell.scss';
import CategoriesContext from '../CategoriesContext';

const DataCell = props => {
    const {children, isTitle, isMiddleRowCell,
        isRightEndCell, position, colorType,
        columnName, isContentCentered,
        isContentBold, isMerged, isInRange} = props;
    const {selectedColumn} = useContext(CategoriesContext);
    const dataCellRef = useRef(null);
    const isSamePhase = columnName.charAt(0) === selectedColumn?.charAt(0);
    const selectedNum = selectedColumn?.charAt(1);
    const isInRangeF = isInRange?.find(range => range == selectedNum) && isSamePhase;

    useEffect(() => {
        if (!dataCellRef?.current) return;

        const links = dataCellRef.current.querySelectorAll('a');
        if (links.length > 0) {
            links.forEach(link => {
                link.target = '_blank';
            });
        }
    }, [dataCellRef]);

    return (
        <div
            ref={dataCellRef}
            className={`sski-c-data-cell sski-c-data-cell--border-radius-${position}  ${
                isTitle ? `pt-2 pb-1 sski-c-data-cell--background-${colorType} has-text-white has-text-centered is-size-5 has-text-weight-medium` : ''
            } ${
                isRightEndCell ? 'mr-1' : ''
            } ${
                isMiddleRowCell ? 'sski-c-data-cell--border-bottom' : 'sski-c-data-set__cell-content-merged-bottom'
            } ${
                !isMiddleRowCell && !isTitle ? `sski-c-data-cell--box-shadow-${colorType}` : ''
            } ${
                !isTitle ? 'sski-c-data-cell--min-height' : ''
            } ${
                !isMerged ? 'p-2 pt-4' : ''
            }
            ${
                isContentCentered ? 'has-text-centered' : ''
            } ${
                isContentBold ? 'has-text-weight-bold is-size-5' : ''
            } ${
                (isInRangeF || selectedColumn === columnName) ? `sski-c-data-cell--background-selected-${colorType}` : ''
            }
                sski-c-data-cell--background--${colorType}`
     }
        >
            {children}
        </div>
    );
};

DataCell.propTypes = {
    isTitle: PropTypes.bool,
    isRightEndCell: PropTypes.bool,
    isMiddleRowCell: PropTypes.bool,
    position: PropTypes.string,
    colorType: PropTypes.string,
    isContentCentered: PropTypes.bool,
    isContentBold: PropTypes.bool,
    columnName: PropTypes.string,
};

DataCell.defaultProps = {
    isTitle: false,
    isRightEndCell: false,
    isMiddleRowCell: false,
    position: '',
    colorType: '',
    isContentCentered: false,
    isContentBold: false,
    columnName: '',
};

export default DataCell;
