import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as dashboardActionTypes from '../dashboardActionTypes';

const loadPhases = function* loadPhases() {
    const {serviceManager} = yield select(state => state.application);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_PHASES_REQUEST,
        ftemStrapiService.getPhases,
    );

    const responseAction = yield take([
        dashboardActionTypes.FETCH_PHASES_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_PHASES_REQUEST_FAILED,
    ]);

    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {phaseDTOs} = response;

        yield put({
            type: dashboardActionTypes.STORE_PHASES,
            payload: {phaseDTOs},
        });
        yield put({
            type: dashboardActionTypes.STORE_PHASES,
            payload: {phaseDTOs},
        });
    }
};

export default loadPhases;
