import {produce} from 'immer';
import {Topic, phaseTypes, Element, subPhaseTypes} from '@swiss-ski/sski-entity-types';
import {isEqual} from 'lodash';
import * as categoryActionTypes from './categoryActionTypes';
import {EMPTY_ELEMENT} from '../utils/ftemConstants';

const initialState = {
    categoryOptions: [],
    selectedCategoriesTableContent: {},
    categoriesLanguage: 'de',
};

/**
 * Dashboard reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */

const categoryReducer = produce((draft, action) => {
    switch (action.type) {
        case categoryActionTypes.STORE_CATEGORIES: {
            const {topicDTOs, responseLanguage} = action.payload;
            draft.categoryOptions = topicDTOs.map(topic => new Topic().fromDTO(topic));
            draft.categoriesLanguage = responseLanguage;
            break;
        }
        case categoryActionTypes.STORE_ELEMENTS: {
            const {elementDTOs, categoryIds} = action.payload;
            const selectedCategoriesTableContent = new Map();
            const selectedCategoryIds = draft.categoryOptions.filter(category => (
                categoryIds.includes(category.id)
            )).map(category => category.id);

            const filterElementsByPhaseType = (elements, subTopicId, phaseType) => {
                const filteredElements = elements.filter(element => (
                    element.subTopic?.itemKey === subTopicId
                    && element.ftemPhase?.name?.includes(phaseTypes[phaseType][0])
                )).map(element => new Element().fromDTO(element));

                if (Object.keys(subPhaseTypes[phaseType]).length === filteredElements.length) {
                    return filteredElements.sort((a, b) => (
                        a.ftemPhase.name.localeCompare(b.ftemPhase.name)
                    ));
                }

                const filteredElementsWithEmptyContent = [];
                Object.keys(subPhaseTypes[phaseType]).forEach(key => {
                    const element = filteredElements.find(element => (
                        element.ftemPhase.name === subPhaseTypes[phaseType][key]
                    ));
                    if (!element) {
                        filteredElementsWithEmptyContent.push({
                            ftemPhase: {
                                name: subPhaseTypes[phaseType][key],
                            },
                            text: EMPTY_ELEMENT,
                        });
                        return;
                    }
                    filteredElementsWithEmptyContent.push(element);
                });

                return filteredElementsWithEmptyContent.sort((a, b) => (
                    a.ftemPhase.name.localeCompare(b.ftemPhase.name)
                ));
            };

            selectedCategoryIds.forEach(categoryId => {
                const subCategories = {};
                const category = draft.categoryOptions.find(category => category.id === categoryId);
                category?.subTopics.sort((subTopicA, subTopicB) => (
                    subTopicA.sortKey - subTopicB.sortKey
                )).forEach(subTopic => {
                    const setRange = [filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.FOUNDATION),
                        filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.TALENT),
                        filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.ELITE),
                        filterElementsByPhaseType(elementDTOs, subTopic.id, phaseTypes.MASTERY)];
                    const rangeOfMerge = {};
                    const compareFn = (firstElm, secondElm) => isEqual(firstElm, secondElm);
                    const addRange = setRange.map(phaseType => phaseType).map(phase => {
                        const phaseTexts = [];
                        const getAllPhases = phase.map((subPhase, subPhaseIndex) => {
                            const getSubPhaseName = subPhase?.ftemPhase?.name;
                            if (phaseTexts.length > 0) {
                                const mergeFn = (elm1, elm2) => compareFn(elm1, elm2);
                                const getText = subPhase?.text;
                                const isMerge = mergeFn(getText, phaseTexts[subPhaseIndex - 1]);
                                const isMultiMerge = () => {
                                    const mergeRecursively = (index, cellMerged) => {
                                        if (index < 0 || !mergeFn(subPhase?.text, phaseTexts[index])) {
                                            return cellMerged + 1;
                                        }
                                        return mergeRecursively(index - 1, cellMerged + 1);
                                    };

                                    const cellMerged = 0;

                                    if (phaseTexts.length > 0) {
                                        return mergeRecursively(phaseTexts.length - 1, cellMerged);
                                    }

                                    return cellMerged + 1;
                                };
                                const getMergeIndex = isMultiMerge();
                                if (isMerge) {
                                    rangeOfMerge[getSubPhaseName] = getMergeIndex;
                                }
                            }
                            phaseTexts.push(subPhase?.text);
                            const addRange = {...subPhase, rangeOfMerge: rangeOfMerge[getSubPhaseName] ?? false};
                            return addRange;
                        });
                        return getAllPhases;
                    });
                    const flatAll = addRange.flat();
                    const isNotAllEmpty = flatAll.find(x => x.text !== EMPTY_ELEMENT);
                    if (isNotAllEmpty) {
                        subCategories[subTopic.id] = {
                            name: subTopic.name,
                            [phaseTypes.FOUNDATION]: addRange[0],
                            [phaseTypes.TALENT]: addRange[1],
                            [phaseTypes.ELITE]: addRange[2],
                            [phaseTypes.MASTERY]: addRange[3],
                        };
                    }
                });
                selectedCategoriesTableContent[categoryId] = {name: category?.name, subCategories};
            });
            draft.selectedCategoriesTableContent = selectedCategoriesTableContent;
            break;
        }
        case categoryActionTypes.RESET_CATEGORY_ELEMENTS: {
            draft.selectedCategoriesTableContent = [];
            draft.categoryOptions = [];
            break;
        }
    }
}, initialState);

export default categoryReducer;
