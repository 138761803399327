import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import * as dashboardActionTypes from '../dashboardActionTypes';
import {SUBTOPICS_ID} from '../../utils/ftemConstants';
import setSportFilter from '../../utils/setSportFilter';


const fetchTopicContents = function* fetchTopicContents() {
    const {serviceManager} = yield select(state => state.application);
    while (true) {
        const ftemStrapiService = serviceManager.loadService('ftemStrapiService');
        const {payload} = yield take(dashboardActionTypes.INITIATE_FETCH_TOPIC_CONTENTS_FLOW);
        const {selectedSubPhaseId, selectedSportId, selectedSportName} = payload;
        if (!selectedSubPhaseId || !selectedSportId) continue;
        let lastIndex;
        const preFilterParamsString = SUBTOPICS_ID.filter(subTopicId => subTopicId !== 'introduction-home')
            .map((key, i) => {
                lastIndex = i;
                return `filters[$or][${i}][itemKey][$eq]=${key}-${selectedSubPhaseId}-${selectedSportName}`;
            }).join('&');
        const filterParamsString = preFilterParamsString + setSportFilter({sportId: selectedSportId, lastIndex});
        yield fork(
            fetchRequest,
            dashboardActionTypes.FETCH_ELEMENTS_REQUEST,
            ftemStrapiService.getElements,
            {filterParamsString},
        );

        const elementsResponseAction = yield take([
            dashboardActionTypes.FETCH_ELEMENTS_REQUEST_SUCCEEDED,
            dashboardActionTypes.FETCH_ELEMENTS_REQUEST_FAILED,
        ]);
        if (!elementsResponseAction.error) {
            const {response} = elementsResponseAction.payload;
            const {elementDTOs} = response;

            yield put({
                type: dashboardActionTypes.STORE_ELEMENTS,
                payload: {
                    elementDTOs,
                },
            });
        }
    }
};

export default fetchTopicContents;
