import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import DataRow from './DataRow';
import DataCell from './DataCell';
import './DataSet.scss';
import DataTableContext from './DataTableContext';
import CategoriesContext from '../CategoriesContext';

const DataSet = props => {
    const {dataSetTitle, headersContent, haveToRerender, children} = props;
    const dataSetTableRef = useRef(null);
    const [parentScrollPosition, setParentScrollPosition] = useState({top: 0, left: 0});
    const [isLeftScroll, setIsLeftScroll] = useState(false);
    const [isTopScroll, setIsTopScroll] = useState(true);
    const [dataSetTitlePositions, setDataSetTitlePositions] = useState({});
    const {scrollPosition} = useContext(DataTableContext);

    useEffect(() => {
        if (!dataSetTableRef?.current) return;
        if (scrollPosition.top !== parentScrollPosition.top) {
            setIsLeftScroll(false);
            setIsTopScroll(true);
            setDataSetTitlePositions({left: `${scrollPosition.left + 35}px`});
            setParentScrollPosition({top: scrollPosition.top, left: scrollPosition.left});
        }
        if (scrollPosition.left !== parentScrollPosition.left) {
            setIsLeftScroll(true);
            setIsTopScroll(false);
            const {top} = dataSetTableRef?.current?.getBoundingClientRect() ?? {};
            setDataSetTitlePositions({top: `${top - 40}px`});
            setParentScrollPosition({top: scrollPosition.top, left: scrollPosition.left});
        }
    },
    [scrollPosition.left,
        scrollPosition.top,
        dataSetTableRef,
        parentScrollPosition,
        isTopScroll]);

    useEffect(() => {
        if (!haveToRerender) return;
        setIsLeftScroll(false);
        setIsTopScroll(true);
        setDataSetTitlePositions({left: `${scrollPosition.left + 40}px`});
    }, [haveToRerender, scrollPosition.left]);

    return (
        <div className="sski-c-data-set p-2 is-position-relative">
            {isLeftScroll && (
                <p
                    style={dataSetTitlePositions}
                    className="sski-c-data-set__title is-position-fixed is-size-5 has-text-weight-bold"
                >
                    {dataSetTitle}
                </p>
            )}
            {isTopScroll && (
                <p
                    style={dataSetTitlePositions}
                    className="sski-c-data-set__title is-position-absolute is-size-5 has-text-weight-bold"
                >
                    {dataSetTitle}
                </p>
            )}
            <div ref={dataSetTableRef} className="sski-c-data-set__data-table">
                <DataRow>
                    {Object.entries(headersContent).map(([key, titlesSet]) => {
                        return titlesSet.map((title, index) => (
                            <DataCell
                                key={`${dataSetTitle}-${title.id}`}
                                isTitle={true}
                                colorType={key.toLowerCase()}
                                position={titlesSet.length === 1
                                    ? 'top'
                                    : index === 0
                                        ? 'top-left'
                                        : index === titlesSet.length - 1
                                            ? 'top-right'
                                            : ''}
                                isRightEndCell={titlesSet.length > 1 && index === titlesSet.length - 1}
                            >
                                {title.name}
                            </DataCell>
                        ));
                    })}
                </DataRow>
                {children}
            </div>
        </div>
    );
};

DataSet.propTypes = {
    dataSetTitle: PropTypes.string.isRequired,
    headersContent: PropTypes.object.isRequired,
    haveToRerender: PropTypes.bool,
};

DataSet.defaultProps = {
    haveToRerender: false,
};

export default DataSet;
