import {produce} from 'immer';
import Link from '@swiss-ski/sski-entity-types/src/topic/Link';
import * as applicationActionTypes from './applicationActionTypes';

/**
 * Initial application domain state
 *
 * @type {Object}
 */
const initialState = {
    serviceManager: null,
    links: [],
    sportId: null,
};

/**
 * Application reducer
 *
 * @param state
 * @param action
 * @returns {Object}
 */
const applicationReducer = produce((draft, action) => {
    switch (action.type) {
        case applicationActionTypes.STORE_LINKS: {
            const {links, linksPrev} = action.payload;
            const createLinks = links.map(link => new Link().fromDTO(link));
            if (linksPrev) {
                createLinks.push(...linksPrev);
            }
            draft.links = createLinks;
            break;
        }
        case applicationActionTypes.SET_SPORT_ID: {
            const {getSportId} = action.payload;
            draft.sportId = getSportId;
            break;
        }
        default:
        // no-op
    }
}, initialState);

export default applicationReducer;
