import React, {useState} from 'react';
import CategoriesContext from './CategoriesContext';

const CategoriesProvider = ({children}) => {
    const [selectedColumn, setSelectedColumn] = useState(null);
    return (
        <CategoriesContext.Provider
            value={{
                selectedColumn,
                setSelectedColumn,
            }}
        >{children}
        </CategoriesContext.Provider>
    );
};


export default CategoriesProvider;
