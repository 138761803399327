import React, {useEffect, useState} from 'react';
import {useRive, Layout, Fit, Alignment, useStateMachineInput} from '@rive-app/react-canvas';
import PropTypes from 'prop-types';
import {phaseNamesMapper} from '../../../utils/ftemConstants';
import {phaseTypes, subPhaseTypes} from '@swiss-ski/sski-entity-types';
import InfoCard from '../InfoCard';

const InfoCards = ({hoveredPhase, selectedPhase, introductionContents, selectedSubphase, isMobile, sortDetails, setHoveredPhase}) => {
    return (
        ((hoveredPhase && selectedPhase && introductionContents) || selectedSubphase) && (
            <div
                className={`${
                    (hoveredPhase === phaseTypes.MASTERY && !selectedSubphase)
                        ? 'is-flex is-justify-content-center is-align-items-center is-flex-direction-row-reverse'
                        : ''}
                                    ${isMobile ? 'sski-c-dashboard-screen__mobile-legend-container' : ''}
                                    sski-c-dashboard-screen__legend-container--${selectedSubphase ? 'subphase' : hoveredPhase.toLowerCase()}`}
            >
                {(!selectedSubphase && !isMobile) && (
                    <div
                        className={`sski-c-dashboard-screen__legend-line--${hoveredPhase.toLowerCase()}`}
                    />
                )}
                {(selectedSubphase && hoveredPhase)
                    ? sortDetails.length > 0 ? null : null
                    : hoveredPhase ? (
                        <InfoCard
                            hoveredPhase={hoveredPhase}
                            selectedPhase={selectedPhase}
                            selectedPhaseTitle={introductionContents[Object.values(subPhaseTypes[hoveredPhase])[0]]?.text || ''}
                            isMobile={isMobile}
                            closeCard={setHoveredPhase}
                        />
                    ) : null}
            </div>
        )
    );
};

const FTEMPillarsRiveAnimation = props => {
    const {onHover, onClick, hoveredPhase, selectedPhase, introductionContents, selectedSubphase, isMobile, sortDetails, setHoveredPhase, clickedPhase} = props;
    const STATE_MACHINE_NAME = 'FTEM Navigator';
    const {RiveComponent, rive} = useRive({
        src: `${process.env.PUBLIC_URL}/assets/animation/ftem_navigation_remastered.riv`,
        stateMachines: STATE_MACHINE_NAME,
        artboard: STATE_MACHINE_NAME,
        // animations: 'FTEM Navigator',
        autoplay: true,
        layout: new Layout({
            fit: Fit.Contain,
            alignment: Alignment.BottomCenter,
        }),
    });
    const [isTClicked, setIsTClicked] = useState(false);
    // const [isPointer, setIsPointer] = useState(false);
    // selector for animation control
    const selectInputE = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-t2');
    const hoverF = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isHoverF');
    const hoverT = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isHoverT');
    const hoverE = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isHover-E');
    const hoverM = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isHover-M');
    const selectF = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isClicked');
    const selectT = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isClickedT');
    const selectE = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isClicked-E');
    const selectM = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isClicked-M');
    const isSelectedF1 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'IsSelected-f1');
    const isSelectedF2 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'IsSelected-f2');
    const isSelectedF3 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'IsSelected-f3');
    const isSelectedT1 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-t1');
    const isSelectedT2 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-t2');
    const isSelectedT3 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-t3');
    const isSelectedT4 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-t4');
    const isSelectedE1 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-e1');
    const isSelectedE2 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-e2');
    const isSelectedM1 = useStateMachineInput(rive, STATE_MACHINE_NAME, 'isSelected-m');

    useEffect(() => {
        if (rive) {
            rive.on('statechange', event => {
                console.log(rive, 'RIve', rive?.contents);
                const allEvents = rive?.prevAnim;
                const currentStateChangeName = event.data.length === 1 ? event.data[0] : null;
                if (!currentStateChangeName) return;
                const splitStateChangeName = currentStateChangeName.split('-');
                const lastSplitStateChangeNameElement = splitStateChangeName[splitStateChangeName.length - 1];
                const formatM = splitStateChangeName[0] === 'm1' ? 'm' : splitStateChangeName[0];
                const isHoverLast = allEvents?.includes('hover');
                const prefixLast = allEvents?.split('-')[0];
                const isIdleNow = currentStateChangeName?.includes('idle');

                console.log(currentStateChangeName, 'currentStateChangeName', phaseNamesMapper[splitStateChangeName[0].toUpperCase()], splitStateChangeName[0]);

                if (currentStateChangeName === 'T-clicked' && !isTClicked) {
                    setIsTClicked(true);
                } else {
                    setIsTClicked(false);
                }

                if (isHoverLast && isIdleNow && prefixLast !== splitStateChangeName[0]) {
                    return;
                }
                rive.prevAnim = event.data[0];

                if (lastSplitStateChangeNameElement === 'hover') {
                    // setIsPointer(true);
                    onHover(phaseNamesMapper[splitStateChangeName[0].toUpperCase()]);
                    onClick('');
                }

                if (lastSplitStateChangeNameElement === 'idle') {
                    onHover('');
                    onClick('');
                    // setIsPointer(false);
                }

                if (lastSplitStateChangeNameElement === 'clicked') {
                    onClick(formatM);
                }
            });
        }
    }, [rive]);

    const handleClick = isSubPhase => {
        if (rive) {
            if (isSubPhase === 'f1') {
                isSelectedF1.fire();
            }
            if (isSubPhase === 'f2') {
                isSelectedF2.fire();
            }
            if (isSubPhase === 'f3') {
                isSelectedF3.fire();
            }

            if (isSubPhase === 't1') {
                isSelectedT1.fire();
            }
            if (isSubPhase === 't2') {
                isSelectedT2.fire();
            }
            if (isSubPhase === 't3') {
                isSelectedT3.fire();
            }
            if (isSubPhase === 't4') {
                isSelectedT4.fire();
            }
            if (isSubPhase === 'e1') {
                isSelectedE1.fire();
            }
            if (isSubPhase === 'e2') {
                isSelectedE2.fire();
            }
            if (isSubPhase === 'm1') {
                isSelectedM1.fire();
            }
            if (isSubPhase === hoveredPhase) {
                const getName = hoveredPhase?.charAt(0);
                if (getName == 'F') {
                    selectF.fire();
                }
                if (getName == 'T') {
                    selectT.fire();
                }
                if (getName == 'E') {
                    selectE.fire();
                }
                if (getName == 'M') {
                    selectM.fire();
                }
                console.log('ARE T PRESENT ?????', hoverT.value, hoveredPhase, 'hoveredPhase', getName, 'getName');
                // hoverT.value = true;
                // selectT.fire();
            }
        }
    };

    // useEffect(() => {
    //     if (!selectedSubphase && hoveredPhase && isSelectedT4) {
    //         console.log('are we in hree', hoveredPhase, rive?.contents);
    //     }
    // }, [selectedSubphase]);

    return (
        <div className="is-flex is-justify-content-center">
            <div
                className="is-flex"
                style={{position: 'relative', width: '80vw', top: '40vh', aspectRatio: '2.6 / 1'}}
            >

                <InfoCards
                    hoveredPhase={hoveredPhase}
                    selectedPhase={selectedPhase}
                    introductionContents={introductionContents}
                    selectedSubphase={selectedSubphase}
                    isMobile={isMobile}
                    sortDetails={sortDetails}
                    setHoveredPhase={setHoveredPhase}
                />
                <RiveComponent />
                {hoveredPhase === 'FOUNDATION' && (
                    <div>
                        <div className="subphase-pillar f1-pillar" onClick={() => handleClick('f1')} />
                        <div className="subphase-pillar f2-pillar" onClick={() => handleClick('f2')} />
                        <div className="subphase-pillar f3-pillar" onClick={() => handleClick('f3')} />
                    </div>
                )}
                {hoveredPhase === 'TALENT' && (
                <div>
                    <div className="subphase-pillar t1-pillar" onClick={() => handleClick('t1')} />
                    <div className="subphase-pillar t2-pillar" onClick={() => handleClick('t2')} />
                    <div className="subphase-pillar t3-pillar" onClick={() => handleClick('t3')} />
                    <div className="subphase-pillar t4-pillar" onClick={() => handleClick('t4')} />
                </div>
                )}
                {hoveredPhase === 'ELITE' && (
                    <div>
                        <div className="subphase-pillar e1-pillar" onClick={() => handleClick('e1')} />
                        <div className="subphase-pillar e2-pillar" onClick={() => handleClick('e2')} />
                    </div>
                )}
                {hoveredPhase === 'MASTERY' && (
                    <div>
                        <div className="subphase-pillar m1-pillar" onClick={() => handleClick('m1')} />
                    </div>
                )}
                {/* <div className={`subphase-pillar ${hoveredPhase?.charAt(0).toLowerCase()}-pillar`} onClick={() => handleClick(hoveredPhase)} /> */}
                {!clickedPhase && (
                    <div
                        className={`subphase-pillar f-pillar ${hoveredPhase === 'FOUNDATION' ? 'hover' : ''}`}
                        onClick={() => handleClick(hoveredPhase)}
                        onMouseEnter={() => hoverF.value = true}
                        onMouseLeave={() => hoverF.value = false}
                    />
                )}
                {!clickedPhase && (
                <div
                    className={`subphase-pillar t-pillar ${hoveredPhase === 'TALENT' ? 'hover' : ''}`}
                    onClick={() => handleClick(hoveredPhase)}
                    onMouseEnter={() => hoverT.value = true}
                    onMouseLeave={() => hoverT.value = false}
                />
                )}
                {!clickedPhase && (
                    <div
                        className={`subphase-pillar e-pillar ${hoveredPhase === 'ELITE' ? 'hover' : ''}`}
                        onClick={() => handleClick(hoveredPhase)}
                        onMouseEnter={() => hoverE.value = true}
                        onMouseLeave={() => hoverE.value = false}
                    />
                )}
                {!clickedPhase && (
                    <div
                        className={`subphase-pillar m-pillar ${hoveredPhase === 'MASTERY' ? 'hover' : ''}`}
                        onClick={() => handleClick(hoveredPhase)}
                        onMouseEnter={() => hoverM.value = true}
                        onMouseLeave={() => hoverM.value = false}
                    />
                )}
                {/* {Object.keys(phaseTypes).map(phase => (phase === 'TALENT' && !selectedSubphase) && <div onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} onClick={() => handleClick()} className={`sski-animation__pillar sski-animation__pillar--${phase.toLowerCase()}`} />)} */}
            </div>
        </div>
    );
};

FTEMPillarsRiveAnimation.propTypes = {
    onHover: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};


export default FTEMPillarsRiveAnimation;
