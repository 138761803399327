import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {useReactToPrint} from 'react-to-print';
import {BlocksRenderer} from '@strapi/blocks-react-renderer';
import {resolveRoute, Link, withRouter} from '@computerrock/formation-router';
import './PrintModal.scss';
import {EMPTY_ELEMENT} from '../../utils/ftemConstants';
import routePaths from '../../routePaths';
import useOutsideClick from '../../utils/useOutsideClick';

const PrintModal = props => {
    const {closeModal, selectedColumn, selectedCategoriesTableContent, history, printTitle} = props;
    const printRef = useRef();
    const modalRef = useRef(null);
    const searchParams = new URLSearchParams(history.location.search);
    const sportId = searchParams.get('sportId');
    const isPrint = searchParams.get('print');
    const phaseTitle = selectedColumn + ' Stufendossier';

    const reactToPrintFn = useReactToPrint({
        documentTitle: printTitle + ' ' + phaseTitle,
        contentRef: printRef,
    });

    const callReactToPrint = () => {
        setTimeout(() => reactToPrintFn(), 500);
    };

    const formatData = () => {
        const allData = [];

        selectedCategoriesTableContent && Object.keys(selectedCategoriesTableContent).map(categoryId => {
            if (!!selectedCategoriesTableContent[categoryId] && typeof selectedCategoriesTableContent[categoryId] === 'object'
                && Object.keys(selectedCategoriesTableContent[categoryId].subCategories).length > 0) {
                Object.keys(selectedCategoriesTableContent[categoryId].subCategories)
                    .map((rowKey, rowKeyIndex) => {
                        const rowsContent = selectedCategoriesTableContent[categoryId].subCategories;
                        Object.entries(rowsContent[rowKey])
                            .filter(([key]) => key !== 'name')
                            .map(([key, rowContentSet]) => {
                                if (!rowContentSet) return null;
                                return rowContentSet.map((rowContent, index) => {
                                    if (rowContent.ftemPhase.name === selectedColumn) {
                                        const catName = selectedCategoriesTableContent[categoryId].name;
                                        const isEmpty = rowContent.text === EMPTY_ELEMENT;
                                        const cellTitle = rowsContent[rowKey].name;
                                        if (allData.find(cat => cat.id === catName)) {
                                            const getData = allData.find(cat => cat.id === catName).content;
                                            allData.find(cat => cat.id === catName).content = [...getData, isEmpty ? cellTitle : rowContent.text];
                                        } else {
                                            allData.push({id: catName, content: [isEmpty ? cellTitle : rowContent.text]});
                                        }
                                    }
                                });
                            });
                    });
            }
        });
        const wrapElm = [
            allData.map(category => createPrintEntry({title: category.id, text: category.content})),
        ];
        return wrapElm;
    };

    const createPrintEntry = props => {
        const {title, text} = props;
        return (
            <div className="sski-c-modal__category-text my-3 pb-2">
                <div className="sski-c-print-category__title mb-5  is-size-5 has-text-weight-bold">{title}</div>
                {text.map((element, index) => (typeof element === 'string' ? <p className="sski-print-category__subtitle">{element}</p> : <BlocksRenderer key={index + '_' + title} content={element} />))}
            </div>
        );
    };

    const openPrint = () => {
        const printQuery = `&print=${selectedColumn}` + `&sportId=${sportId}`;
        if (isPrint) {
            searchParams.delete('print');
            searchParams.delete('sportId');
        }
        const setPrintWindow = searchParams + printQuery;
        return resolveRoute(routePaths.DOSSIER, {}, {search: setPrintWindow});
    };

    useOutsideClick(modalRef, closeModal);

    return (
        <div className="modal is-active">
            <div className="modal-background" />
            <div className={`sski-c-print-modal ${isPrint ? 'print-active' : ''}`}>
                <button
                    type="button"
                    className="sski-c-link-modal__close-button"
                    aria-label="close"
                    onClick={closeModal}
                >
                    <img alt="exit" src={`${process.env.PUBLIC_URL}/assets/icons/exit-circle.svg`} />
                </button>
                <div ref={modalRef} className="sski-c-link-modal__card modal-card">
                    <header className="sski-c-link-modal__head sski-c-link-modal__head-- modal-card-head">
                        <p className={` modal-card-title has-text-centered`}>
                            {phaseTitle}
                        </p>
                        {!isPrint && (
                        <Link
                            to
                            target="_blank"
                            className="sski-c-print-modal__link-out"
                            rel="noreferrer"
                            to={() => openPrint()}
                        >
                            <svg
                                className="sski-c-link-modal__link-out_icon sski-c-link-modal__link-out_icon--"
                                viewBox="0 0 32 32"
                            >
                                <use xlinkHref="/sprite.svg#symbol-link-out-usage" />
                            </svg>
                        </Link>
                        )}
                    </header>
                    <section className="sski-c-print-modal__body modal-card-body container p-0">
                        <div className="a4-container">
                            <div className="a4-page" id="print-content" ref={printRef}>
                                <div className="ssski-c-print-modal__title is-flex is-justify-content-space-between is-align-items-center">
                                    <h3 className="is-size-5 has-text-weight-bold">
                                        {printTitle}: {phaseTitle}
                                    </h3>
                                    <button
                                        type="button"
                                        className="sski-c-modal__print_button button is-white is-rounded
                                        is-flex is-justify-content-space-evenly is-responsive is-size-6
                                        has-text-weight-normal"
                                        onClick={() => callReactToPrint()}
                                    >
                                        <img alt="print" src={`${process.env.PUBLIC_URL}/assets/icons/print.svg`} />
                                        Drucken
                                    </button>
                                </div>
                                {formatData()}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        selectedCategoriesTableContent: state.categories.selectedCategoriesTableContent,
    };
};

export default withRouter(connect(mapStateToProps)(PrintModal));
