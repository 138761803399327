import {select, fork, take, put} from 'redux-saga/effects';
import {fetchRequest} from '@computerrock/formation-core';
import {subPhaseTypes} from '@swiss-ski/sski-entity-types';
import * as dashboardActionTypes from '../dashboardActionTypes';
import setSportFilter from '../../utils/setSportFilter';

const loadIntroductionContents = function* loadIntroductionContents() {
    const {serviceManager} = yield select(state => state.application);
    const ftemStrapiService = serviceManager.loadService('ftemStrapiService');
    const {sportId} = yield select(state => state.application);
    const {sports} = yield select(state => state.dashboard);

    let lastIndex;

    const getSportIdName = sports.find(sport => sport.idNum == sportId)?.id;

    const preFilterParamsString = Object.keys(subPhaseTypes).map((key, index) => {
        lastIndex = index;
        return `filters[$or][${index}][itemKey][$eq]=introduction-home-${Object.values(subPhaseTypes[key])[0].toLowerCase()}-${getSportIdName}`;
    }).join('&');
    const getFilterSport = setSportFilter({sportId, lastIndex});

    const filterParamsString = preFilterParamsString + getFilterSport;

    yield fork(
        fetchRequest,
        dashboardActionTypes.FETCH_INTRODUCTION_CONTENTS_REQUEST,
        ftemStrapiService.getElements,
        {filterParamsString},
    );
    const responseAction = yield take([
        dashboardActionTypes.FETCH_INTRODUCTION_CONTENTS_REQUEST_SUCCEEDED,
        dashboardActionTypes.FETCH_INTRODUCTION_CONTENTS_REQUEST_FAILED,
    ]);
    if (!responseAction.error) {
        const {response} = responseAction.payload;
        const {elementDTOs} = response;
        yield put({
            type: dashboardActionTypes.STORE_INTRODUCTION_CONTENTS,
            payload: {
                elementDTOs,
            },
        });
    }
};

export default loadIntroductionContents;
